import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Status,
  FormInput,
} from "ui-kit-ck-consultant";

import { months } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export class TableUnreparable extends Component {
  getLabels = () => {
    return [
      {
        id: "nbFolders",
        name: "Nombre de dossiers",
      },
      {
        id: "ageVehicleGlobal",
        name: "Âge véhicule (m)",
      },
      {
        id: "VEI",
        name: "Taux de VEI",
      },
      {
        id: "missionSD29Global",
        name: "Délai mission-SD29 (j)",
      },
      {
        id: "SD29_2Global",
        name: "Délai SD29 n°1-n°2 (j)",
      },
      {
        id: "missionSD09Global",
        name: "Délai mission-SD09 (j)",
      },
      {
        id: "missionVisitGlobal",
        name: "Délai mission – visite avant travaux (j)",
      },
      {
        id: "missionVisitRolling",
        name: "Délai mission – visite avant tvx - véhicule roulant (j)",
      },
      {
        id: "missionVisitNonRolling",
        name: "Délai mission – visite avant tvx - véhicule non roulant (j)",
      },
      {
        id: "missionVisitWorkGlobal",
        name: "Mission - visite pendant travaux",
      },
      {
        id: "visitClosingGlobal",
        name: "Délai visite-dépôt (j)",
      },
      {
        id: "visitSD29Global",
        name: "Délai visite-SD29 (j)",
      },
      {
        id: "SD29ClosingGlobal",
        name: "Délai SD29-dépôt (j)",
      },
      {
        id: "missionDepotGlobal",
        name: "Délai mission-dépôt (j)",
      },
      {
        id: "VRADE",
        name: "Coût VRADE (€ TTC)",
      },
      {
        id: "vrade4600",
        name: "Dossiers (VRADE 0/4600 € TTC) (%)",
      },
      {
        id: "vrade4600Plus",
        name: "Dossiers (VRADE >=4600 € TTC) (%)",
      },
      {
        id: "vehicleTotalPriceDutyFree",
        name: "Prix à neuf avec options HT",
      },
      {
        id: "averageMileage",
        name: "Kilométrage moyen",
      },
      {
        id: "correctiveRateTechnicalReport",
        name: "Taux de correctif selon marché HT dans bilan technique",
      },
      {
        id: "resultTechnicalReportDutyFree",
        name: "Taux de +/- values HT dans bilan technique",
      },
      {
        id: "countSignature",
        name: "Signatures électroniques lancées",
      },
      {
        id: "countSignatureOk",
        name: "Signatures électroniques validées",
      },
      {
        id: "rateSignatureOkSignature",
        name: "Taux de Signatures électroniques validées/lancées",
      },
      {
        id: "rateSignatureFolder",
        name: "Taux de signatures électroniques lancées/tous les dossiers",
      },
      {
        id: "rateSignatureOkFolder",
        name: "Taux de signatures électroniques validées/tous les dossiers",
      },
      {
        id: "notRunningVehicleRate",
        name: "Taux de non roulant",
      },
    ];
  };

  static contextType = AuthContext;

  getEvolution = (data1, data2, name) => {
    const inversedColorList = [
      "Taux de VEI",
      "Délai mission-SD29 (j)",
      "Délai visite-SD29 (j)",
      "Délai mission-dépôt (j)",
      "Coût VRADE (€ HT)",
      "Dossiers (VRADE >=4600 € HT) (%)",
      "Délai SD29 n°1-n°2 (j)",
      "Délai mission-SD09 (j)",
      "Délai visite-dépôt (j)",
      "Délai SD29-dépôt (j)",
      "Délai mission – visite avant travaux (j)",
      "Coût VRADE (€ TTC)",
      "Dossiers (VRADE >=4600 € TTC) (%)",
      "Prix à neuf avec options HT",
    ];

    if (data1 && data2) {
      let value = Math.round((data2 / data1) * 100 - 100);
      if (inversedColorList.includes(name)) {
        if (value > 0) {
          return (
            <Status
              className="bg-red-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else if (value < 0) {
          return (
            <Status
              className="bg-green-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else {
          return <Status className="bg-blue-light black w-100" text="0 %" />;
        }
      } else {
        if (value > 0) {
          return (
            <Status
              className="bg-green-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else if (value < 0) {
          return (
            <Status
              className="bg-red-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else {
          return <Status className="bg-blue-light black w-100" text="0 %" />;
        }
      }
    } else {
      return <Status className="bg-blue-light black w-100" />;
    }
  };

  render() {
    return (
      <FadeIn className={"overflow-x-auto"}>
        <Table>
          <Thead>
            <Tr>
              <Th hide></Th>
              <Th radiusTopLeft>Cible</Th>
              <Th>N-1</Th>
              <Th>N</Th>
              <Th spaceRight>N/N-1</Th>
              {months.map((month, idx) => (
                <Th key={idx} spaceLeft={idx === 0}>
                  {month}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {this.getLabels().map((row, idx) => {
              return (
                <Tr key={idx}>
                  <Th radiusTopLeft={idx === 0}>{row.name}</Th>
                  <Td style={{ padding: 0 }}>
                    {this.context.role === 1 ? (
                      <FormInput
                        type="number"
                        styleInput={{
                          border: "none",
                          padding: "0 10px",
                          fontSize: 12,
                          height: 36,
                          borderRadius: 0,
                        }}
                        style={{
                          width: "80px",
                        }}
                        value={
                          this.props.performanceAgency[
                            `NR${this.props.company || ""}_${row.id}`
                          ] || ""
                        }
                        onChange={(e) =>
                          this.props.updatePerformanceAgency(
                            `NR${this.props.company || ""}_${row.id}`,
                            e.target.value
                          )
                        }
                        onBlur={() => this.props.leaveUpdatePerformanceAgency()}
                      />
                    ) : (
                      this.props.performanceAgency[
                        `NR${this.props.company || ""}_${row.id}`
                      ] || ""
                    )}
                  </Td>
                  <Td>
                    {this.props.data[0][row.id]
                      ? this.props.data[0][row.id]
                      : null}
                  </Td>
                  <Td>
                    {this.props.data[1][row.id]
                      ? this.props.data[1][row.id]
                      : null}
                  </Td>
                  <Td spaceRight>
                    {this.getEvolution(
                      this.props.data[0][row.id],
                      this.props.data[1][row.id],
                      row.name
                    )}
                  </Td>
                  <Td spaceLeft>
                    {this.props.data[2][row.id]
                      ? this.props.data[2][row.id]
                      : null}
                  </Td>
                  <Td>
                    {this.props.data[3] ? this.props.data[3][row.id] : null}
                  </Td>
                  <Td>
                    {this.props.data[4] ? this.props.data[4][row.id] : null}
                  </Td>
                  <Td>
                    {this.props.data[5] ? this.props.data[5][row.id] : null}
                  </Td>
                  <Td>
                    {this.props.data[6] ? this.props.data[6][row.id] : null}
                  </Td>
                  <Td>
                    {this.props.data[7] ? this.props.data[7][row.id] : null}
                  </Td>
                  <Td>
                    {this.props.data[8] ? this.props.data[8][row.id] : null}
                  </Td>
                  <Td>
                    {this.props.data[9] ? this.props.data[9][row.id] : null}
                  </Td>
                  <Td>
                    {this.props.data[10] ? this.props.data[10][row.id] : null}
                  </Td>
                  <Td>
                    {this.props.data[11] ? this.props.data[11][row.id] : null}
                  </Td>
                  <Td>
                    {this.props.data[12] ? this.props.data[12][row.id] : null}
                  </Td>
                  <Td>
                    {this.props.data[13] ? this.props.data[13][row.id] : null}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </FadeIn>
    );
  }
}

export default TableUnreparable;
