import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import {
  Status,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  FormInput,
} from "ui-kit-ck-consultant";

import { months } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export class TablePRE extends Component {
  getLabels = () => {
    return [
      {
        id: "recommendationPRE_RATE",
        name: "Taux de préconisation PRE",
      },
      {
        id: "transformationPRE_RATE",
        name: "Taux de transformation PRE",
      },
      {
        id: "PRE_RATE",
        name: "Taux de PRE",
      },
      {
        id: "rateOldPRE",
        name: "Taux de PRE (véhicules +5ans et uniquement pièces sup ou égale à 150 €)",
      },
      {
        id: "rateRemovePRE",
        name: "Taux de réparation des pièces amovibles",
      },
      {
        id: "proportionPRE_RATE",
        name: "Proportion de PRE",
      },
      {
        id: "approvedPRE_RATE",
        name: "Taux de PRE garages agréés",
      },
      {
        id: "notApprovedPRE_RATE",
        name: "Taux de PRE garages non agréés",
      },
      {
        id: "preRateTarget",
        name: "Taux de PRE sur pièces cibles",
      },
      {
        id: "gainPRE_COST",
        name: "Gain PRE",
      },
      {
        id: "ratePRE0",
        name: "Taux PRE Pare-chocs",
      },
      {
        id: "ratePRE1",
        name: "Taux PRE Feux",
      },
      {
        id: "ratePRE2",
        name: "Taux PRE Phare",
      },
      {
        id: "ratePRE3",
        name: "Taux PRE Capot",
      },
      {
        id: "ratePRE4",
        name: "Taux PRE Porte avant",
      },
      {
        id: "ratePRE5",
        name: "Taux PRE Porte arriere",
      },
      {
        id: "ratePRE6",
        name: "Taux PRE Aile avant",
      },
      {
        id: "ratePRE7",
        name: "Taux PRE Calandre",
      },
      {
        id: "ratePRE8",
        name: "Taux PRE Hayon/Coffre/malle",
      },
    ];
  };

  static contextType = AuthContext;

  getEvolution = (data1, data2, name) => {
    const inversedColorList = [];

    if (data1 && data2) {
      let value = Math.round((data2 / data1) * 100 - 100);
      if (inversedColorList.includes(name)) {
        if (value > 0) {
          return (
            <Status
              className="bg-red-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else if (value < 0) {
          return (
            <Status
              className="bg-green-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else {
          return <Status className="bg-blue-light black w-100" text="0 %" />;
        }
      } else {
        if (value > 0) {
          return (
            <Status
              className="bg-green-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else if (value < 0) {
          return (
            <Status
              className="bg-red-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else {
          return <Status className="bg-blue-light black w-100" text="0 %" />;
        }
      }
    } else {
      return <Status className="bg-blue-light black w-100" />;
    }
  };

  render() {
    return (
      <FadeIn className={"overflow-x-auto"}>
        {this.props.data ? (
          <Table>
            <Thead>
              <Tr>
                <Th hide></Th>
                <Th radiusTopLeft>Cible</Th>
                <Th>N-1</Th>
                <Th>N</Th>
                <Th spaceRight>N/N-1</Th>
                {months.map((month, idx) => (
                  <Th key={idx} spaceLeft={idx === 0}>
                    {month}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {this.getLabels().map((row, idx) => {
                return (
                  <Tr key={idx}>
                    <Th radiusTopLeft={idx === 0}>{row.name}</Th>
                    <Td style={{ padding: 0 }}>
                      {this.context.role === 1 ? (
                        <FormInput
                          type="number"
                          styleInput={{
                            border: "none",
                            padding: "0 10px",
                            fontSize: 12,
                            height: 36,
                            borderRadius: 0,
                          }}
                          style={{
                            width: "80px",
                          }}
                          value={
                            this.props.performanceAgency[
                              `P${this.props.company || ""}_${row.id}`
                            ] || ""
                          }
                          onChange={(e) =>
                            this.props.updatePerformanceAgency(
                              `P${this.props.company || ""}_${row.id}`,
                              e.target.value
                            )
                          }
                          onBlur={() =>
                            this.props.leaveUpdatePerformanceAgency()
                          }
                        />
                      ) : (
                        this.props.performanceAgency[
                          `P${this.props.company || ""}_${row.id}`
                        ] || ""
                      )}
                    </Td>
                    <Td>
                      {this.props.data[0][row.id]
                        ? this.props.data[0][row.id]
                        : null}
                    </Td>
                    <Td>
                      {this.props.data[1][row.id]
                        ? this.props.data[1][row.id]
                        : null}
                    </Td>
                    <Td spaceRight>
                      {this.getEvolution(
                        this.props.data[0][row.id],
                        this.props.data[1][row.id],
                        row.name
                      )}
                    </Td>
                    <Td spaceLeft>
                      {this.props.data[2][row.id]
                        ? this.props.data[2][row.id]
                        : null}
                    </Td>
                    <Td>
                      {this.props.data[3] ? this.props.data[3][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[4] ? this.props.data[4][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[5] ? this.props.data[5][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[6] ? this.props.data[6][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[7] ? this.props.data[7][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[8] ? this.props.data[8][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[9] ? this.props.data[9][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[10] ? this.props.data[10][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[11] ? this.props.data[11][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[12] ? this.props.data[12][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[13] ? this.props.data[13][row.id] : null}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : null}
      </FadeIn>
    );
  }
}

export default TablePRE;
